import React from "react"
import { Container } from "react-bootstrap"
import * as styles from "./index.module.scss"

export default function Footer() {
  return (
    <Container className="p-4 text-center" id={styles.footer}>
      <p>
        &copy; 2025 Mark Sterling Gober, LLC. All rights reserved. No
        representation is made that the quality of legal services to be
        performed is greater than the quality of legal services performed by
        other lawyers.
      </p>
      <p>
        Site by J<sup className="text-light-green">3</sup> Consulting.
      </p>
    </Container>
  )
}
